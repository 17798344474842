import { breakpoint } from 'src/styles'
import { CommonStyles } from './styles'
import { mixin } from 'src/styles'
import { LiveSchedule } from 'src/components/LiveSchedule'
import { Link } from 'src/components/Link'
import moment from 'moment'
import { RichText } from 'src/components/Display/RichText'
import { storage } from 'src/storage'
import { useMember } from 'src/providers/MemberProvider'
import { useUpcomingReservations } from 'src/hooks/useUpcomingReservations'
import { useWeeklyClassActivity } from 'src/hooks/useWeeklyClassActivity'
import { ViewCard } from 'src/views/Profile/ViewCard'
import { isClassReplayOrVOD } from 'src/utils/bookmarkUtils'

const WEEKLY_CLASS_GOAL = 5

export const WeeklyTracker = () => {
  const { currentMember, username, isSelf } = useMember()
  const { id } = currentMember || {}
  const { upcomingReservations } = useUpcomingReservations(currentMember?.id, isSelf)
  const { weeklyClassActivity } = useWeeklyClassActivity(currentMember?.id, isSelf)

  const views = weeklyClassActivity?.completedClasses || []
  const weekStart = moment.parseZone(weeklyClassActivity?.weekStart).format('MMMM D')
  const weekEnd = moment.parseZone(weeklyClassActivity?.weekEnd).format('MMMM D')

  const lastAddedView = JSON.parse(storage.session.getItem(`lastAddedView${id}`))

  if (
    lastAddedView &&
    views.filter((view) => view?.classDto?.id === lastAddedView?.classDto?.id).length === 0
  ) {
    views.push(lastAddedView)
    views.sort((a, b) => (moment(a.viewStartTime).isAfter(moment(b.viewStartTime)) ? -1 : 1))
  }

  return (
    <Styles.Container>
      <CommonStyles.Title center compact centraLight>
        Weekly Tracker
      </CommonStyles.Title>
      <CommonStyles.CurrentWeek center centraBook>
        {weekStart} - {weekEnd}
      </CommonStyles.CurrentWeek>
      <Styles.Header>
        <RichText.Medium uppercase>
          {`STRIVE FOR FIVE: ${weeklyClassActivity?.completedCount || 0}/${WEEKLY_CLASS_GOAL}`}
        </RichText.Medium>
        <CommonStyles.RedirectLink to={`/${username || 'profile'}/class-history`}>
          Class History
        </CommonStyles.RedirectLink>
      </Styles.Header>
      {views.map((view) => (
        <Styles.ViewCard
          view={view}
          key={view.id}
          trackingContext={{
            context: 'weeklyTracker',
            pageName: '/profile',
            videoId: view.id,
            videoType: isClassReplayOrVOD(view),
          }}
        />
      ))}
      {!weeklyClassActivity?.completedCount && (
        <React.Fragment>
          <CommonStyles.EmptyStateCta>
            Classes you complete this week will be listed below. Not sure where to start? Check out
            our live schedule!
          </CommonStyles.EmptyStateCta>
          {upcomingReservations?.currentWeek?.length === 0 ? (
            <LiveSchedule isProfileSchedule={true} />
          ) : (
            <Styles.RainbowRule />
          )}
        </React.Fragment>
      )}
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    ${mixin.cardBoxShadow}
    padding-top: 16px;

    ${breakpoint.large} {
      width: 350px;
    }
  `,
  Header: styled.div`
    ${mixin.flexSpaceBetween}
    margin: 20px 0 12px;
    padding: 0 16px;

    ${breakpoint.largeAndUp} {
      padding: 0;
      margin: 20px 20px 14px;
    }
  `,
  Link: styled(Link)`
    text-decoration: underline;
  `,
  ViewCard: styled(ViewCard)`
    box-shadow: none;
    margin-bottom: 0;
  `,
  RainbowRule: styled.div`
    height: 2px;
    width: 100%;
    background: linear-gradient(
      90deg,
      var(--yellow) 0.7%,
      var(--mediumPink) 29.86%,
      var(--lightBlue) 52.12%,
      var(--lavender) 99.2%
    );
    transform: rotate(180deg);
  `,
}
