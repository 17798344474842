import { client } from 'src/api'

export const getRawObeClassById = (id) => client.get(`/api/v4/obe_classes/${id}`)

export const getObeClassById = async (id) => {
  const { data: video } = await client.get(`/api/v4/obe_classes/${id}`)

  video.instructorNames = video?.instructors.map(({ name }) => name?.trim()) || []

  return video
}

export const addObeClassToCalendar = (id, rsvpFromContext, startTime) =>
  client.post(`/api/v4/obe_classes/${id}/add_to_calendar`, {
    rsvpFromContext,
    startTime,
  })

export const removeObeClassFromCalendar = (id) =>
  client.post(`/api/v4/obe_classes/${id}/remove_from_calendar`)

export const getObeClasses = ({
  page = 1,
  perPage = 24,
  selectedExclusionFilters = {},
  selectedFilters = {},
  search = '',
  sort = '',
}) =>
  client.post('/api/v4/obe_classes', {
    page,
    per_page: perPage,
    popular_music_only: selectedExclusionFilters.popularMusicOnly,
    hide_completed_classes: selectedExclusionFilters.hideCompletedClasses,
    selected_filters: selectedFilters,
    ...(search ? { search } : {}),
    ...(sort ? { sort } : {}),
  })

export const getAvailableFilters = ({ selectedExclusionFilters = {}, selectedFilters = {} }) =>
  client.post('/api/v4/obe_classes/available_filters', {
    popular_music_only: selectedExclusionFilters.popularMusicOnly,
    hide_completed_classes: selectedExclusionFilters.hideCompletedClasses,
    selected_filters: selectedFilters,
  })

export const getMyFilters = async () => await client.get('/api/v4/members/my_filters')

export const getAvailableFilterCounts = ({
  selectedExclusionFilters = {},
  selectedFilters = {},
  search = '',
}) =>
  client.post('/api/v4/obe_classes/available_filter_counts', {
    popular_music_only: selectedExclusionFilters.popularMusicOnly,
    hide_completed_classes: selectedExclusionFilters.hideCompletedClasses,
    selected_filters: selectedFilters,
    ...(search ? { search } : {}),
  })

export const getObeClassesByFacets = ({ perPage = 3, page = 1, selectedFilters = {} }) =>
  client.post('/api/v4/obe_classes', {
    page,
    per_page: perPage,
    selected_filters: selectedFilters,
  })
