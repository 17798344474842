import { Loader } from 'src/components/Display/Loader'
import { getLiveClassesByDate, getUpcomingLiveClasses } from 'src/models/liveClass'
import { useAsync } from 'react-async-hook'
import { UpcomingEvents } from 'src/views/Home/User/UpcomingEvents'
import { breakpoint } from 'src/styles'
import moment from 'moment'

export const LiveSchedule = ({ isProfileSchedule = false }) => {
  const { result: todaysLiveClasses, loading: loadingTodaysLiveClasses } = useAsync(
    getLiveClassesByDate,
    [moment().utc().format('YYYY-MM-DD')]
  )
  const { result: upcomingLiveClasses, loading: loadingUpcomingLiveClasses } = useAsync(
    getUpcomingLiveClasses,
    []
  )

  return (
    <Loader isLoading={loadingUpcomingLiveClasses || loadingTodaysLiveClasses}>
      <Styles.Container>
        <Styles.UpcomingEvents
          upcomingEvents={upcomingLiveClasses}
          lastEventToday={todaysLiveClasses?.[todaysLiveClasses?.length - 1]}
          isProfileSchedule={isProfileSchedule}
        />
      </Styles.Container>
    </Loader>
  )
}

const Styles = {
  Container: styled.div`
    width: 100%;
    ${breakpoint.largeAndUp} {
      padding-bottom: 20px;
    }
  `,
  UpcomingEvents: styled(UpcomingEvents)`
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border: none;
    height: fit-content;
    padding-bottom: 12px;

    > a {
      margin: 0 auto;

      ${breakpoint.mediumAndDown} {
        margin-block: 20px;
      }

      ${breakpoint.large} {
        margin: 20px 16px;
      }
    }

    ${breakpoint.xlargeAndUp} {
      margin: 20px 16px;
    }
  `,
}
