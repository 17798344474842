import { getUpcomingReservations } from 'src/models/member'
import useSwr, { mutate, useSWRConfig } from 'swr'

export { setUpcomingReservations, clearCache } from './setters'

export const generateUpcomingReservationsUserKey = (userId, isSelf) =>
  `/api/v3/members/upcoming_reservations/${isSelf ? `me/${obe.storage_keys.swr_me_key}` : userId}`
export const loadUpcomingReservations = (USER_KEY) => mutate(USER_KEY)

export const useUpcomingReservations = (userId, isSelf = false) => {
  const USER_KEY = generateUpcomingReservationsUserKey(userId, isSelf)
  const { cache } = useSWRConfig()

  const { data, error } = useSwr(USER_KEY, () => getUpcomingReservations(userId), {
    revalidateOnMount: !cache.has(USER_KEY),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const isLoadingInitialState = !data && !error
  const defaultResponse = {
    upcomingReservations: {},
    isLoadingInitialState,
  }

  if (error || !data) {
    return defaultResponse
  }

  return {
    upcomingReservations: data,
  }
}

export default useUpcomingReservations
