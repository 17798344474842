import moment from 'moment'
import { secondsToMinutes } from './secondsToMinutes'
import { getEquipmentLabel } from './getEquipmentLabel'
import { formatContentSubtitle } from './formatContentSubtitle'

export const checkIsLiveClass = (obeClass) => obeClass?.classFormat === 'live'

export const checkIsArchived = (obeClass) => obeClass?.status === 'archived'

export const checkIsOnDemandWithFeed = (obeClass) => {
  return !checkIsLiveClass(obeClass) && !!obeClass?.feedFmStationId
}

export const checkIsLiveClassToday = (obeClass) =>
  moment(obeClass?.startTime).isSame(moment(), 'day')

export const checkIsWelcomeVideo = (obeClass) =>
  !!obeClass?.tags?.find((tag) => tag?.tagType === 'classType' && tag?.slug === 'welcome-video')

export const formatScheduleClassCard = (classItem) => {
  return {
    duration: secondsToMinutes(classItem.duration),
    equipment: getEquipmentLabel(classItem.equipment, 'No Equipment'),
    subtitle: formatContentSubtitle(classItem),
    title: classItem.title,
    instructors: classItem.instructors,
    specialGuest: classItem.specialGuest,
    theme: classItem.theme,
    category: classItem.category,
    classType: classItem.classType,
    classId: classItem.id,
    classFormat: classItem?.classFormat,
  }
}
