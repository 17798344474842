import { checkIsLiveClass } from './obeClassUtils'

export const isStarOnEvent = (event, currentUser) =>
  !!event?.instructors
    ?.map(({ id }) => id)
    ?.some((instructorId) => currentUser?.profile?.preferences?.facets?.includes(instructorId)) &&
  !!currentUser?.profile?.preferences?.facets?.includes(event.classType?.id)

export const isStarOnInstructor = (instructor, currentUser) => {
  const facetPrefs = currentUser?.profile?.preferences?.facets

  return (
    facetPrefs?.includes(instructor?.id || instructor?.key) &&
    instructor?.classTypes?.some((classType) => facetPrefs?.includes(classType.id))
  )
}

export const isStarOnProgram = (program, currentUser) => {
  const fitnessLevel = currentUser?.profile?.fitnessLevel

  return (
    !!currentUser?.profile.preferences?.programs?.includes(program?.key) &&
    program?.difficulty
      ?.map(({ slug }) => slug)
      .some(
        (slug) =>
          slug === fitnessLevel || (slug === 'open-level' && fitnessLevel === 'intermediate')
      )
  )
}

export const isStarOnVideo = (video, currentUser) => {
  const fitnessLevel = currentUser?.profile?.fitnessLevel
  const facetPrefs = currentUser?.profile?.preferences?.facets
  const classDifficulties = video?.tags
    ?.filter(({ tagType }) => tagType === 'classDifficulty')
    ?.map(({ slug }) => slug)
  const difficultyMatch =
    classDifficulties?.includes(fitnessLevel) || classDifficulties?.includes('open-level')
  const classTypes = video?.tags
    ?.filter(({ tagType }) => tagType === 'classType')
    ?.map(({ id }) => id)

  return (
    difficultyMatch &&
    !!classTypes.some((classTypeId) => facetPrefs?.includes(classTypeId)) &&
    !!video?.instructors
      ?.map(({ id }) => id)
      ?.some((instructorId) => facetPrefs?.includes(instructorId))
  )
}

export const isStarOnObeClass = (obeClass, currentUser) =>
  checkIsLiveClass(obeClass)
    ? isStarOnEvent(obeClass, currentUser)
    : isStarOnVideo(obeClass, currentUser)
