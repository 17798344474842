import useSwr from 'swr'
import { getStats } from 'src/models/obeClassViews'

const STATS_KEY = 'OBE_CLASS_VIEWS_STATS'

export const useStats = () =>
  useSwr(STATS_KEY, getStats, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  })
