import { getWeeklyClassActivity } from 'src/models/member'
import useSwr, { mutate, useSWRConfig } from 'swr'

export const generateWeeklyClassActivityKey = (userId, isSelf) =>
  `/api/v3/members/weekly_class_activity/${isSelf ? `me/${obe.storage_keys.swr_me_key}` : userId}`
export const loadWeeklyClassActivity = () => mutate(generateWeeklyClassActivityKey())
export { clearCache } from './setters'

export const useWeeklyClassActivity = (userId, isSelf = false) => {
  const USER_KEY = generateWeeklyClassActivityKey(userId, isSelf)
  const { cache } = useSWRConfig()

  const { data, error } = useSwr(USER_KEY, () => getWeeklyClassActivity(userId), {
    revalidateOnMount: !cache.has(USER_KEY),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const isLoadingInitialState = !data && !error
  const defaultResponse = {
    weeklyClassActivity: {},
    isLoadingInitialState,
  }

  if (error || !data) {
    return defaultResponse
  }

  return {
    weeklyClassActivity: data,
  }
}

export default useWeeklyClassActivity
