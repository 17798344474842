import { App } from 'src/components/App'
import { Event } from 'src/components/Event'
import { eventsHasMultiInstructors } from 'src/utils/eventsHasMultiInstructors'
import { isStarOnEvent } from 'src/utils/preferenceUtils'
import moment from 'moment'
import { Render } from 'src/components/Event/renderStyles'

export const UpcomingEvents = ({
  className,
  currentUser,
  isProfileSchedule,
  isStreamLive = true,
  lastEventToday,
  showRecStars,
  trackingContext,
  upcomingEvents = [],
}) => {
  let previousEventDate

  return (
    <App.ImpressionTracker
      tracking={{
        obeClassIds: upcomingEvents?.map((liveClass) => liveClass?.id),
        obeClassNames: upcomingEvents?.map((liveClass) => liveClass?.title),
      }}
    >
      <Render.Container className={className} isStreamLive={isStreamLive}>
        <Render.Inner>
          {upcomingEvents.map((event, idx) => {
            const dateHeader =
              (idx === 0 || previousEventDate.isBefore(moment(event.startTime), 'day')) &&
              getTitle(event, event.id + idx)

            previousEventDate = moment(event.startTime)

            return (
              <React.Fragment key={idx}>
                {dateHeader && <Render.DateHeader>{dateHeader}</Render.DateHeader>}
                <Event
                  event={event}
                  isCompact
                  isStarred={showRecStars && isStarOnEvent(event, currentUser)}
                  styles={Render.Event}
                  trackingContext={trackingContext}
                  hasMultiInstructors={eventsHasMultiInstructors(upcomingEvents)}
                  isProfileSchedule={isProfileSchedule}
                />
              </React.Fragment>
            )
          })}
        </Render.Inner>
        <Render.Button
          to={getSchedulePath(lastEventToday)}
          tracking={{ context: 'upcoming_events' }}
        >
          View the Full Live Schedule
        </Render.Button>
      </Render.Container>
    </App.ImpressionTracker>
  )
}

const getTitle = (event) => {
  const titleDate = event ? moment(event.startTime) : moment()

  const titleText = moment(titleDate).isSame(new Date(), 'day')
    ? `Today, ${moment(titleDate).format('MMMM Do')}`
    : moment(titleDate).format('dddd, MMMM Do')

  return <Render.Title center>{titleText}</Render.Title>
}

const getSchedulePath = ({ startTime, duration } = {}) => {
  const currentTime = moment()
  const lastEventEndingTime = moment(startTime).add(duration, 'm')
  const hasLastEventEnded = lastEventEndingTime.isBefore(currentTime)

  return hasLastEventEnded
    ? `/schedule/${currentTime.add(1, 'd').format('YYYY-MM-DD')}`
    : '/schedule'
}
