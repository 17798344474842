import { breakpoint, mixin } from 'src/styles'
import { useUser } from 'src/hooks/useUser'

const TotalNumbers = ({ showSeeAll, totalClasses, totalMinutes, totalClassTypes, isOverview }) => {
  const { currentUser } = useUser()

  return (
    <Container isOverview={isOverview}>
      {isOverview && <OverviewTitle>Your stats</OverviewTitle>}
      <HeaderText isOverview={isOverview}>Your total numbers</HeaderText>
      <Metrics>
        <Stat>
          <Count>{totalClasses}</Count>
          <Label>classes</Label>
        </Stat>
        <Stat>
          <Count>{totalMinutes}</Count>
          <Label>minutes</Label>
        </Stat>
        <Stat>
          <Count>{totalClassTypes}</Count>
          <Label>class types</Label>
        </Stat>
      </Metrics>
      {showSeeAll && (
        <SeeAllLink href={`/${currentUser?.profile.username}/stats`}>
          <SeeAllText>See all</SeeAllText>
        </SeeAllLink>
      )}
    </Container>
  )
}

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;

  ${mixin.cardBoxShadow}

  ${({ isOverview }) =>
    !isOverview &&
    css`
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 26px;
    `}
`

const OverviewTitle = styled.div`
  font-size: 32px;
  font-weight: 200;
  text-align: center;
  color: var(--nearBlack);
`

const HeaderText = styled.div`
  font-size: 32px;
  font-weight: 300;
  color: var(--nearBlack);
  margin-bottom: 15px;
  text-align: left;

  ${({ isOverview }) =>
    isOverview &&
    css`
      text-align: center;
      font-size: 16px;
    `}

  ${breakpoint.smallAndDown} {
    text-align: center;
  }
`

const Metrics = styled.div`
  width: 100%;

  & > *:first-of-type {
    margin-top: 0;
  }

  & > *:last-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  & > *:last-child:after {
    display: none;
  }

  ${breakpoint.largeAndUp} {
    display: flex;
  }
`

const Stat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 42px;
    width: 1px;
    border-right: 1px solid var(--nearBlack);
    transform: translate(-50%, -50%);
    top: 50%;
  }

  ${breakpoint.smallAndDown} {
    padding-top: 10px;
    padding-bottom: 20px;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 100%;
      height: 1px;
      width: 72px;
      border-bottom: 1px solid var(--nearBlack);
      transform: translate(-50%, 0%);
    }
  }
`

const Count = styled.div`
  font-size: 32px;
  background: linear-gradient(1deg, #ff95dc 0%, #dd01ad 100%);
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  color: #dd01ad;
`

const Label = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: #3a3f49;
`

const SeeAllLink = styled.a``

const SeeAllText = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--nearBlack);
  text-align: center;
  margin-top: 25px;
`

export default TotalNumbers
