import { api, client } from 'src/api'

export const getClassesTaken = async () => {
  let response
  let classesTaken

  if (api.sessionState) {
    try {
      response = await client.get('/api/v3/obe_class_views/classes_taken')
    } catch (error) {
      return {}
    }

    if (response?.data) {
      classesTaken = response?.data
    }

    return classesTaken
  }
}

export const sendVideoCompletion = async (obeClassId) => {
  await client.post('/api/v3/obe_class_views/client_tracked_view', {
    obe_class_id: obeClassId,
  })
}

export const getStats = async () => {
  let response
  let stats

  if (api.sessionState) {
    try {
      response = await client.get('/api/v3/obe_class_views/stats')
    } catch (error) {
      return {}
    }

    if (response?.data) {
      stats = response?.data
    }

    return stats
  }
}
