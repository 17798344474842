import { mixin, breakpoint } from 'src/styles'
import { CommonStyles } from './styles'
import { isClassReplayOrVOD } from 'src/utils/bookmarkUtils'
import { getMemberDisplayName } from 'src/utils/formattingUtils'
import { getClassHistory } from 'src/models/user'
import { Loader } from 'src/components/Display/Loader'
import { useAsync } from 'react-async-hook'
import { useMember } from 'src/providers/MemberProvider'
import { useResponsive } from 'src/hooks/useResponsive'
import { ViewCard } from './ViewCard'

export const ClassHistoryOverviewCard = () => {
  const { currentMember, username, isSelf } = useMember()
  const { result, loading } = useAsync(getClassHistory, [
    currentMember?.id,
    undefined,
    undefined,
    1,
    2,
  ])
  const { isMediumAndUp } = useResponsive()
  const maxHistoryLength = isMediumAndUp ? 2 : 1

  const formatTracking = (item) => {
    const { id } = item?.classDto
    const identity = isSelf ? 'profile' : 'friends'

    return {
      context: 'classCard',
      pageName: `${identity}/class-history-overview`,
      videoId: id,
      videoType: isClassReplayOrVOD(item?.classDto),
    }
  }

  return (
    <Styles.Container>
      <CommonStyles.Title center compact centraLight>
        Class History
      </CommonStyles.Title>
      <CommonStyles.CardSubtitle>
        {`${getMemberDisplayName(currentMember, {
          useFirstName: true,
        })}'s Recent Classes`}
      </CommonStyles.CardSubtitle>
      <Loader isLoading={loading}>
        <Styles.CardsContainer>
          {result?.data?.completedClasses?.slice(0, maxHistoryLength).map((view) => (
            <Styles.ViewCard view={view} key={view?.id} trackingContext={formatTracking(view)} />
          ))}
        </Styles.CardsContainer>
        {result?.totalResults > maxHistoryLength && (
          <CommonStyles.RedirectLink
            classHistorySeeAll
            to={`/${username || 'profile'}/class-history`}
          >
            See All
          </CommonStyles.RedirectLink>
        )}
      </Loader>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    ${mixin.cardBoxShadow}
    padding: 16px;

    ${breakpoint.smallAndDown} {
      padding: 16px 0;
    }
  `,
  CardsContainer: styled.div`
    ${mixin.spaceChildrenHorizontal('16px')}
    display: flex;
  `,
  ViewCard: styled(ViewCard)`
    width: 50%;
    margin-bottom: 0;

    ${breakpoint.smallAndDown} {
      width: 100%;
    }
  `,
}
