import { breakpoint, mixin } from 'src/styles'
import { ClassHistoryOverviewCard } from 'src/views/Profile/ClassHistoryOverviewCard'
import { CollectionsOverview } from './CollectionsOverview'
import { FriendsOverviewCard } from 'src/views/Profile/FriendsOverviewCard'
import { FullContainer } from 'src/components/Display/Container'
import { isEmpty } from 'lodash'
import { ProgramsProgress } from './ProgramsProgress'
import { useMember } from 'src/providers/MemberProvider'
import { useSavedClasses } from 'src/hooks/useSavedClasses'
import { WeeklyTracker } from 'src/views/Profile/WeeklyTracker'
import StatsTotalNumbersView from 'src/views/Profile/StatsTotalNumbers'
import { ObeSchedules } from './ObeSchedules'
import { EmptyStatsBanner } from './EmptyStatsBanner'

export const Overview = () => {
  const { canUseSocialFeatures, currentMember, currentProgramData, isSelf } = useMember()

  const { favoriteVideos } = useSavedClasses(!isSelf && currentMember?.id)

  const hasFavorites = favoriteVideos?.length > 0

  return (
    <Styles.FullContainer page>
      <Styles.InnerContainer>
        {isSelf && (
          <Styles.LeftContentContainer hasFavorites={hasFavorites}>
            <EmptyStatsBanner />
            <StatsTotalNumbersView />
            <Styles.SchedulesContainer>
              <ObeSchedules hideLiveSchedule height={500} />
            </Styles.SchedulesContainer>
            <WeeklyTracker />
          </Styles.LeftContentContainer>
        )}
        <Styles.RightContentContainer hasFavorites={hasFavorites || !isSelf}>
          {!isEmpty(currentProgramData) && <ProgramsProgress />}
          <CollectionsOverview />
          {(!isSelf || canUseSocialFeatures) && <FriendsOverviewCard />}
          {!isSelf && currentMember?.membershipActivity?.classesCompleted > 0 && (
            <ClassHistoryOverviewCard />
          )}
        </Styles.RightContentContainer>
      </Styles.InnerContainer>
    </Styles.FullContainer>
  )
}

const Styles = {
  FullContainer: styled(FullContainer)`
    display: flex;
    flex-direction: column;
  `,
  InnerContainer: styled.div`
    ${breakpoint.largeAndUp} {
      display: flex;
    }
  `,
  LeftContentContainer: styled.div`
    ${mixin.spaceChildrenVertical('30px')}

    ${breakpoint.largeAndUp} {
      padding-right: 34px;

      ${({ hasFavorites }) =>
        hasFavorites
          ? css`
              width: 375px;
              flex-shrink: 0;
              box-sizing: content-box;
            `
          : css`
              flex: 0 0 34.6%;
              max-width: 34.6%;
            `}
    }
  `,
  RightContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    ${mixin.spaceChildrenVertical('30px')}

    ${breakpoint.mediumAndDown} {
      margin-top: 30px;
    }

    ${breakpoint.largeAndUp} {
      ${({ hasFavorites }) =>
        hasFavorites
          ? css`
              flex-grow: 1;
            `
          : css`
              flex: 0 0 65.4%;
              max-width: 65.4%;
            `}
    }
  `,
  SchedulesContainer: styled.div`
    height: 500px;
    ${mixin.cardBoxShadow}
  `,
}
