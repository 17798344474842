import { breakpoint, mixin } from 'src/styles'
import { Image } from 'src/components/Display/Image'
import { Link } from 'src/components/Link'
import { StatsAccordion, useStatsAccordionState } from 'src/views/Profile/StatsTab/components'
import { widths } from 'src/utils/image'

const ClassesPerInstructor = ({ classesPerInstructor }) => {
  const {
    expanded,
    items: classes,
    showToggle,
    onAccordionClick,
  } = useStatsAccordionState({ items: classesPerInstructor })

  return (
    <Styles.Container>
      <Styles.HeaderText>Classes per instructor</Styles.HeaderText>
      <StatsAccordion expanded={expanded} showToggle={showToggle} onClick={onAccordionClick}>
        <Styles.InstructorsWrapper>
          {classes.map((instructor) => (
            <Styles.InstructorWrapper
              key={instructor.id}
              to={`/instructors/${instructor.slug}`}
              doNotTrack
            >
              <Styles.InstructorAvator circle src={instructor.avatarUrl} cdnWidth={widths.avatar} />
              <Styles.InstructorClassCount>
                {instructor.totalClassesPerInstructor}
              </Styles.InstructorClassCount>
              <Styles.InstructorName>{instructor.instructorName}</Styles.InstructorName>
            </Styles.InstructorWrapper>
          ))}
        </Styles.InstructorsWrapper>
      </StatsAccordion>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    padding: 16px;

    ${mixin.cardBoxShadow}
  `,
  HeaderText: styled.div`
    font-size: 32px;
    line-height: 42px;
    font-weight: 300;
    color: var(--nearBlack);
    margin-bottom: 17px;

    ${breakpoint.smallAndDown} {
      text-align: center;
    }
  `,
  InstructorsWrapper: styled.div`
    width: 100%;

    & > *:first-of-type {
      margin-top: 0;
    }

    & > *:last-child {
      margin-right: 0;
    }

    ${breakpoint.largeAndUp} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  `,

  InstructorWrapper: styled(Link)`
    flex: 0 0 33.333333%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 21px;

    :hover {
      text-decoration: none;
    }
  `,
  InstructorName: styled.div`
    text-align: center;
    font-weight: 400;
    font-size: 16px;
  `,
  InstructorAvator: styled(Image)`
    flex-grow: 0;
    flex-shrink: 0;
    width: 48px;
  `,
  InstructorClassCount: styled.div`
    color: #2f85ff;
    font-weight: 400;
    font-size: 24px;
    margin-top: 6px;
  `,
}

export default ClassesPerInstructor
