import { breakpoint, mixin } from 'src/styles'
import { CollectionCard } from 'src/components/BookmarkCollection/CollectionCard'
import { COLLECTION_TYPES } from 'src/models/collections'
import { CommonStyles } from './styles'
import { getAssetUrlCollectionBySlug } from 'src/models/asset'
import { isFavoritesCollection } from 'src/utils/isFavoritesCollection'
import { Spinner } from 'src/components/Spinner'
import { useAsync } from 'react-async-hook'
import useBookmarkCollections from 'src/hooks/useBookmarkCollections'
import { useMember } from 'src/providers/MemberProvider'
import { useResponsive } from 'src/hooks/useResponsive'
import { useSavedClasses } from 'src/hooks/useSavedClasses'

export const CollectionsOverview = () => {
  const { currentMember, isSelf, username } = useMember()
  const { savedVideos } = useSavedClasses(!isSelf && currentMember?.id)
  const { bookmarkCollections } = useBookmarkCollections(!isSelf && currentMember?.id)
  const { isMediumAndUp } = useResponsive()

  const { result: assets, loading } = useAsync(getAssetUrlCollectionBySlug, [
    'bookmark-collections',
  ])

  return (
    <Styles.Container>
      <CommonStyles.Title compact centraLight>
        Collections
      </CommonStyles.Title>
      <CommonStyles.CardSubtitle>
        {isSelf ? 'MY SAVED CLASSES' : `${currentMember?.profile?.firstName}'S SAVED CLASSES`}
      </CommonStyles.CardSubtitle>
      {loading ? (
        <Spinner />
      ) : (
        <Styles.CardContainer>
          {isSelf && isMediumAndUp && (
            <Styles.CollectionCard
              id={COLLECTION_TYPES.ALL.LOWERCASE}
              name={COLLECTION_TYPES.ALL.UPPERCASE}
              numVideos={savedVideos?.length}
              thumbnailImage={assets?.['default-collections-placeholder']}
            />
          )}
          {bookmarkCollections?.slice(0, isSelf || !isMediumAndUp ? 1 : 2).map((collection) => {
            const isFavorites = isFavoritesCollection(collection?.name) && collection.isRequired
            const thumbnailImage = isFavorites
              ? assets?.['favorites-collection-placeholder']
              : collection.thumbnailImage

            return (
              <Styles.CollectionCard
                key={collection.id}
                id={collection.id}
                isFavorites={isFavorites}
                name={collection.name}
                numVideos={collection.numVideos}
                thumbnailImage={thumbnailImage || assets?.['default-collections-placeholder']}
              />
            )
          })}
        </Styles.CardContainer>
      )}
      <CommonStyles.RedirectLink
        to={`${username}/collections`}
        tracking={{ context: 'profile_see_all_collections' }}
      >
        See All
      </CommonStyles.RedirectLink>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    ${mixin.cardBoxShadow}
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;

    ${breakpoint.mediumAndUp} {
      padding: 16px;
    }
  `,
  CardContainer: styled.div`
    display: flex;
    margin-bottom: 10px;
  `,
  CollectionCard: styled(CollectionCard)`
    max-width: 311px;
    width: 100%;
    height: 311px;
    margin: 0 10px;
  `,
}
